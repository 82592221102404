import type { FormEvent } from 'react'
import { useCallback, useState } from 'react'

import { Button, H1, P } from '@ocho/aurora'
import { captureFeedback, lastEventId as getLastEventId } from '@sentry/react'
import { IconArrowUp, IconCheck } from '@tabler/icons-react'
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth'
import type { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import Copy from '@/components/Copy'
import { Box, Flex } from '@/components/system'
import TextInput from '@/components/TextInput'

import { ValueToCopy } from '@/GlobalStyles'
import { HttpStatus } from '@/utils/constants/enums'

import oops from './oops.svg'
import { ErrorContainer, SentryDescription } from './styled'

const MESSAGE_TIMEOUT = 3000

type PossibleError = AxiosError | Error | unknown

type Props = { error?: PossibleError; eventId?: string }

function getErrorType(error?: PossibleError) {
  if (typeof error !== 'object' || error === null) return undefined
  if (!('status' in error) || !('response' in error)) return undefined
  const httpStatus = error.status || (error as AxiosError).response?.status
  if (httpStatus === HttpStatus.NotFound) return 'notFound'
  if (httpStatus === HttpStatus.ServerError) return 'serverError'
  if (httpStatus === HttpStatus.BadGateway) return 'badGateway'
  return undefined
}

export default function AppError({ error, eventId }: Props): JSX.Element {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [isSent, setIsSent] = useState(false)

  const lastEventId = eventId || getLastEventId()

  const errorType = getErrorType(error)

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const user = await getCurrentUser().catch(() => null)
      const userAttributes = await fetchUserAttributes().catch(() => null)

      captureFeedback({
        associatedEventId: lastEventId,
        email: userAttributes.email,
        message,
        name: user.username,
      })

      setMessage('')

      setIsSent(true)
      setTimeout(() => setIsSent(false), MESSAGE_TIMEOUT)
    },
    [lastEventId, message],
  )

  return (
    <ErrorContainer>
      <img alt="" src={oops} />

      <Flex
        alignItems="center"
        flexDirection="column"
        gap="var(--space--small)"
        justifyContent="center"
        maxWidth="var(--size--dialog-narrow)"
      >
        <H1>{t('modules.appError.error.title', { context: errorType })}</H1>
        <P>{t('modules.appError.message', { context: errorType })}</P>
      </Flex>

      {errorType !== 'notFound' && (
        <>
          {Boolean(lastEventId) && (
            <Flex
              flexDirection="column"
              gap="var(--space--xsmall)"
              padding="var(--space--xsmall)"
            >
              <P>{t('modules.appError.error.message')}</P>
              <Box marginTop="var(--space--medium)">
                <SentryDescription>
                  {t('modules.appError.eventId.description')}
                </SentryDescription>

                <Copy value={lastEventId}>
                  {({ copy }) => (
                    <ValueToCopy
                      onClick={copy}
                      title={t('modules.appError.eventId.title')}
                    >
                      {lastEventId}
                    </ValueToCopy>
                  )}
                </Copy>
              </Box>
            </Flex>
          )}
          <Flex
            onSubmit={handleSubmit}
            as="form"
            flexDirection="column"
            paddingTop="var(--space--medium)"
          >
            <TextInput
              onChange={(e) => setMessage(e.target.value)}
              label={t('modules.appError.message.label')}
              value={message}
              multiline
              required
            />
            <Button
              $variant="primary"
              alignSelf="end"
              disabled={isSent}
              type="submit"
            >
              {isSent
                ? t('modules.appError.sent.label')
                : t('modules.appError.send.label')}
              {isSent ? <IconCheck /> : <IconArrowUp />}
            </Button>
          </Flex>
        </>
      )}
    </ErrorContainer>
  )
}
