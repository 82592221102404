import styled from 'styled-components'

export const RootContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'navbar main';
  flex-grow: 1;
`

export const RootContent = styled.main<{ $paddingless?: boolean }>`
  display: grid;
  grid-area: main;
  min-height: 0;
`
