import { create } from 'zustand'
import { combine, persist } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

import { TimeZones } from '@/utils/constants/enums'

export type AgentLocation = Record<string, string>

/**
 * Store
 */

export const usePreferencesStore = create(
  persist(
    combine(
      {
        currentLocation: null as AgentLocation | null,
        currentTimeZone: TimeZones.UsPacific,
      },
      (set) => ({
        setCurrentTimezone: (timeZone: TimeZones) =>
          set((state) => ({ ...state, currentTimeZone: timeZone })),
        setLocation: (location: AgentLocation) =>
          set((state) => ({ ...state, currentLocation: location })),
      }),
    ),
    {
      name: 'ocho-preferences-v2',
    },
  ),
)

/**
 * Helpers
 */

export function usePreferredTimeZone() {
  return usePreferencesStore(
    useShallow(
      ({ currentTimeZone, setCurrentTimezone }) =>
        [currentTimeZone, setCurrentTimezone] as const,
    ),
  )
}

export function usePreferredLocation() {
  return usePreferencesStore(
    useShallow(
      ({ currentLocation, setLocation }) =>
        [currentLocation, setLocation] as const,
    ),
  )
}

export function useSetPreferredLocation() {
  return usePreferencesStore(useShallow(({ setLocation }) => setLocation))
}
