import type { ReactElement, ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { Button, Text } from '@ocho/aurora'
import { Description, Portal, Title, Trigger } from '@radix-ui/react-dialog'
import { ErrorBoundary } from '@sentry/react'
import { IconX } from '@tabler/icons-react'

import AppError from '@/modules/AppError'

import { Flex } from '@/components/system'

import {
  ColorSchemeVariables,
  useColorSchemeContext,
} from '@/utils/hooks/useColorScheme'

import * as Styled from './styled'

interface DialogProps {
  allowOverflow?: boolean
  children?: ReactNode
  defaultOpen?: boolean
  dismissible?: boolean
  drawer?: boolean
  drawerSize?: 'default' | 'large' | 'xlarge'
  footerActions?: ReactNode
  footerActionsPosition?: 'left' | 'right'
  headerActions?: ReactNode
  narrow?: boolean
  onClose?: () => any
  title: ReactElement | string
  trigger?: ReactElement | string
  triggerAsChild?: boolean
}

const FOOTER_ACTIONS_POSITION_MAP = {
  left: 'flex-start',
  right: 'flex-end',
}

/*
 * Dialog
 * Trigger: To use the trigger option, you need to send the prop `defaultOpen` as false.
 * This is to avoid the auto open and trigger the event when the trigger is pressed.
 *
 * HeaderActions: To use the header actions you should send the component actions like this:
 * <Container> <Component1> <Component2> ... <Container/> container could be a <div> or <React.Fragment>
 *
 * FooterActions: To use the footer actions you should send the component actions like this:
 * <Container> <Component1> <Component2> ... <Container/> container could be a <div> or <React.Fragment>
 */
export default function Dialog(props: DialogProps) {
  const {
    allowOverflow = true,
    children,
    defaultOpen = true,
    dismissible = true,
    drawer = false,
    drawerSize = 'default',
    footerActions,
    footerActionsPosition = 'right',
    headerActions,
    narrow = false,
    onClose,
    title,
    trigger,
    triggerAsChild,
  } = props

  const [open, setOpen] = useState(defaultOpen)
  const { colorScheme } = useColorSchemeContext()
  const isDarkMode = colorScheme === ColorSchemeVariables.dark

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen && onClose) onClose()
    setOpen(isOpen)
  }

  function renderChildren() {
    if (typeof children === 'string') {
      return <Description>{children}</Description>
    }

    return children
  }

  const handleOnDismiss = useCallback(
    (event) => {
      if (!dismissible) event.preventDefault()
    },
    [dismissible],
  )

  return (
    <Styled.RootContainer onOpenChange={handleOpenChange} open={open}>
      {Boolean(trigger) && (
        <Trigger asChild>
          {triggerAsChild ? (
            trigger
          ) : (
            <Button $variant="secondary">{trigger}</Button>
          )}
        </Trigger>
      )}
      <Portal>
        <Styled.StyledOverlay $isDarkMode={isDarkMode} />
        <Styled.ContentContainer
          onEscapeKeyDown={handleOnDismiss}
          onPointerDownOutside={handleOnDismiss}
          $drawer={drawer}
        >
          <Styled.Content
            $drawer={drawer}
            $drawerSize={drawerSize}
            narrow={narrow}
          >
            <ErrorBoundary
              fallback={(data) => (
                <>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    paddingBottom="var(--space--medium)"
                  >
                    <Flex alignItems="center">
                      <Styled.Close>
                        <IconX size={18} />
                      </Styled.Close>
                    </Flex>
                  </Flex>
                  <AppError {...data} />
                </>
              )}
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="var(--space--medium)"
              >
                <Flex alignItems="center">
                  {dismissible ? (
                    <Styled.Close>
                      <IconX size={18} />
                    </Styled.Close>
                  ) : null}
                  {title ? (
                    <Title asChild>
                      {typeof title === 'string' ? (
                        <Text $size="large" $weight="bold">
                          {title}
                        </Text>
                      ) : (
                        title
                      )}
                    </Title>
                  ) : null}
                </Flex>
                <Flex justifyContent="space-between">
                  {headerActions ? (
                    <Flex gap="var(--space--small)">{headerActions}</Flex>
                  ) : null}
                </Flex>
              </Flex>
              <Styled.DescriptionContainer $allowOverflow={allowOverflow}>
                {renderChildren()}
              </Styled.DescriptionContainer>
              {footerActions ? (
                <Flex
                  justifyContent={
                    FOOTER_ACTIONS_POSITION_MAP[footerActionsPosition]
                  }
                  alignItems="center"
                  gap="var(--space--small)"
                  paddingTop="var(--space--medium)"
                >
                  {footerActions}
                </Flex>
              ) : null}
            </ErrorBoundary>
          </Styled.Content>
        </Styled.ContentContainer>
      </Portal>
    </Styled.RootContainer>
  )
}
