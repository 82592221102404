import type { MutationKey, QueryKey } from '@tanstack/react-query'

import { getResponseErrorMessage } from '@/utils/api/interceptors/utils'
import { UUID_REGEX } from '@/utils/validation/regex'

const UNSUCCESSFUL_ERROR_NAMES: (string | undefined)[] = [
  'Error',
  'AxiosError',
  'API Error',
]

function getErrorName(
  defaultName: 'MutationError' | 'QueryError',
  error?: Error,
) {
  if (typeof error === 'string') {
    return error
  }

  if (UNSUCCESSFUL_ERROR_NAMES.includes(error?.name)) {
    return defaultName
  }

  return error?.name ?? defaultName
}

export function getResponseContext(response: any) {
  const responseData = response?.data

  if (!responseData) return null

  return {
    data: JSON.stringify(responseData, null, 2),
    'error.code': responseData.errorCode,
    'error.message': getResponseErrorMessage(responseData?.errorMessage),
  }
}

export function normalizeKey(queryKey?: MutationKey | QueryKey): string[] {
  if (!Array.isArray(queryKey)) return []

  return queryKey?.map((key) =>
    typeof key === 'string'
      ? UUID_REGEX.test(key as string)
        ? '{uuid}'
        : key
      : '{options}',
  )
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message
  }
  if (Array.isArray(error)) {
    return error.join(' ')
  }
  if (
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return String(error.message)
  }

  return 'Unknown error'
}

export class QueryError extends Error {
  constructor(originalError?: Error) {
    const message = getErrorMessage(originalError)

    super(message)

    this.name = getErrorName('QueryError', originalError)
    this.cause = originalError
    this.stack = originalError?.stack
  }
}

export class MutationError extends Error {
  constructor(originalError?: Error) {
    const message = getErrorMessage(originalError)

    super(message)

    this.name = getErrorName('MutationError', originalError)
    this.cause = originalError
    this.stack = originalError?.stack
  }
}
