import {
  Arrow as PopoverPrimitiveArrow,
  Close as PopoverPrimitiveClose,
  Content as PopoverPrimitiveContent,
  Trigger as PopoverPrimitiveTrigger,
} from '@radix-ui/react-popover'
import styled, { keyframes } from 'styled-components'

const slideUpFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideRightFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const slideDownFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideLeftFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const Trigger = styled(PopoverPrimitiveTrigger)<{ asChild?: any }>`
  min-width: fit-content;
`

export const Content = styled(PopoverPrimitiveContent)<{
  noMargin?: boolean
  noPadding?: boolean
}>`
  padding: ${({ noPadding }) =>
    noPadding ? 0 : 'var(--space--small) var(--space--medium)'};
  margin: ${({ noMargin }) => (noMargin ? 0 : '0 var(--space--medium)')};
  background-color: var(--color--background-alt__neutral);
  border: 1px solid var(--color--background__neutral);
  border-radius: var(--corner_radius--large);
  z-index: var(--z_index--overlay);
  box-shadow: var(--dialog--box--shadow);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  max-width: var(--size--popover);

  &[data-state='closed'] {
    display: none;
  }

  &[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpFade};
  }

  &[data-state='open'][data-side='top'] {
    animation-name: ${slideDownFade};
  }

  &[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftFade};
  }

  &[data-state='open'][data-side='left'] {
    animation-name: ${slideRightFade};
  }
`

export const Arrow = styled(PopoverPrimitiveArrow)`
  margin-top: -1px;
  fill: var(--color--background-alt__neutral);
`

export const ShadowArrow = styled(PopoverPrimitiveArrow)`
  position: relative;
  margin-top: -1px;
  fill: var(--color--background__neutral);
  width: 12px;
  height: 6px;
  z-index: -2;
  left: 1px;
`

export const Close = styled(PopoverPrimitiveClose)`
  margin-top: var(--space--small);
  background-color: var(--color--solid__primary);
  color: var(--color--blanco);
  border-radius: var(--space--small);
  padding: var(--space--small) var(--space--medium);
`
