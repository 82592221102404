import styled from 'styled-components'

export const Container = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  min-width: 0;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: var(--font_size--small);

  p {
    font-size: var(--font_size--medium);
    margin-bottom: var(--space--xsmall);
  }
`

export const Select = styled.select<{
  $outlined?: boolean
  $primary?: boolean
  $transparent: boolean
  readOnly?: boolean
}>`
  background-color: var(--color--background-alt__neutral);
  display: block;
  border: 1px solid var(--color--background__neutral);
  padding: var(--space--small) var(--space--medium);
  border-radius: var(--space--small);
  font-size: var(--font_size--medium);
  color: ${({ $primary }) =>
    $primary ? `var(--color--solid__primary)` : `var(--color--text__neutral)`};
  ${({ $outlined }) =>
    $outlined &&
    `background-color: transparent;
    border: none;`}

  option {
    font-size: var(--font_size--small);

    [selected] {
      font-weight: var(--font_weight--bold);
    }
  }

  &:disabled {
    background-color: var(--color--background__neutral);
    color: var(--color--text__neutral);
    cursor: not-allowed;
  }

  ${({ $transparent }) => $transparent && `background-color: transparent;`}

  &[readonly] {
    appearance: none;
    border: none;
    padding: var(--space--small) 0;
    background-color: transparent;
  }
`
export const reactSelectModeStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: 'var(--color--background__solid)',
  }),
  input: (base) => ({
    ...base,
    color: 'var(--color--text__default)',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: 'var(--color--background__solid)',
    color: 'var(--color--text__default)',
    minWidth: '100%',
    width: 'max-content',
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'var(--color--background__solid)',
    color: 'var(--color--text__default)',
    display: 'flex',
    whiteSpace: 'nowrap',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--color--text__default)',
  }),
}
