import { useState } from 'react'

import { General } from '@/utils/errors'

const TIMEOUT = 2000

/**
 * useClipboard hook
 * inspired by {@link https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-clipboard/use-clipboard.ts}
 * @param { number } timeout
 */
function useClipboard({ timeout = TIMEOUT } = {}) {
  const [copyTimeout, setCopyTimeout] = useState(null)
  const [state, setState] = useState({
    copied: false,
    error: null,
  })

  const handleCopyResult = (value: boolean) => {
    clearTimeout(copyTimeout)
    setCopyTimeout(
      setTimeout(
        () => setState((prevState) => ({ ...prevState, copied: false })),
        timeout,
      ),
    )
    setState((prevState) => ({ ...prevState, copied: value }))
  }

  const copy = (valueToCopy: any, allowToCopy: boolean) => {
    if ('clipboard' in navigator && allowToCopy) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => handleCopyResult(true))
        .catch((err) => setState((prevState) => ({ ...prevState, error: err })))
    } else {
      setState({ copied: false, error: new Error(General.clipboard) })
    }
  }

  const reset = () => {
    setState({ copied: false, error: null })
    clearTimeout(copyTimeout)
  }

  return { copy, reset, state }
}

export default useClipboard
