import styled from 'styled-components'
import type {
  AlignSelfProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  FlexProps,
  GridProps,
  HeightProps,
  JustifySelfProps,
  LayoutProps,
  MarginProps,
  PaddingProps,
  PositionProps,
  SpaceProps,
} from 'styled-system'
import {
  alignSelf,
  border,
  color,
  flex,
  flexbox,
  grid,
  justifySelf,
  layout,
  margin,
  padding,
  position,
  space,
  system,
} from 'styled-system'

type PointerEventsProps = {
  pointerEvents?:
    | 'all'
    | 'auto'
    | 'fill'
    | 'none'
    | 'painted'
    | 'stroke'
    | 'visible'
    | 'visibleFill'
    | 'visiblePainted'
    | 'visibleStroke'
}

const pointerEvents = system({
  pointerEvents: true,
})

type BoxProps = AlignSelfProps &
  BorderProps &
  ColorProps &
  FlexProps &
  GridProps &
  HeightProps &
  JustifySelfProps &
  LayoutProps &
  MarginProps &
  PaddingProps &
  PointerEventsProps &
  PositionProps &
  SpaceProps

export const Box = styled.div<BoxProps>`
  ${flex}
  ${grid}
  ${color}
  ${space}
  ${padding}
  ${border}
  ${layout}
  ${margin}
  ${position}
  ${pointerEvents}
  ${justifySelf}
  ${alignSelf}
`

export const Flex = styled(Box)<
  { gap?: number | string } & BoxProps & FlexboxProps
>`
  display: flex;
  ${({ gap }) =>
    gap ? `gap: ${typeof gap === 'string' ? gap : `${gap}px`};` : undefined}
  ${flexbox}
`

export const Grid = styled(Box)<
  {
    alignItems?: string
    autoFlow?: string
    gap?: number | string
    justifyItems?: string
  } & BoxProps
>`
  display: grid;
  ${({ gap }) =>
    gap ? `gap: ${typeof gap === 'number' ? `${gap}px` : gap};` : undefined}
  ${({ autoFlow }) => (autoFlow ? `grid-auto-flow: ${autoFlow};` : undefined)}
  ${({ alignItems }) =>
    alignItems ? `align-items: ${alignItems};` : undefined}
  ${({ justifyItems }) =>
    justifyItems ? `justify-items: ${justifyItems};` : undefined}

  ${grid}
`
