/**
 * Verify that the browser has all the required features to run the app.
 * @throws {Error}
 */
export function verifyEnv() {
  if (globalThis.fetch === undefined) throw new Error('Missing fetch')
  if (globalThis.Promise === undefined) throw new Error('Missing Promise')
  if (String.prototype.includes === undefined)
    throw new Error("Missing 'String.prototype.includes'")
  if (Object.entries === undefined) throw new Error("Missing 'Object.entries'")
  if (Object.fromEntries === undefined)
    throw new Error("Missing 'Object.fromEntries'")
  if ((globalThis.document as any).documentMode)
    throw new Error('Is Internet Explorer')
}
