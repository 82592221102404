// Import Sentry first
import '@/utils/sentry'

import { captureException } from '@sentry/react'
import { createRoot } from 'react-dom/client'
import type { FutureConfig } from 'react-router-dom'
import { RouterProvider } from 'react-router-dom'

import browserRouter from '@/Router'
import '@/utils/aws/amplify'
import { verifyEnv } from '@/utils/env'
import '@/utils/localization'

import './reset.css'

const container = document.getElementById('root')
const reactRoot = createRoot(container)

const future: Partial<FutureConfig> = {
  v7_relativeSplatPath: true,
  v7_startTransition: true,
}

try {
  verifyEnv()

  reactRoot.render(<RouterProvider future={future} router={browserRouter} />)
} catch (error: any) {
  captureException(error)

  // Display actionable message
  document.body.innerHTML += '<p>Please update your browser.</p>'
}
