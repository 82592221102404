import { HttpStatus } from '@/utils/constants/enums'
import {
  LICENSES_VALIDATION,
  UUID_REGEX,
  VIN_VALIDATION,
  WEBFLOW_COLLECTION_ID_REGEX,
} from '@/utils/validation/regex'

export const IGNORED_RESPONSE_STATUS_CODES = [
  HttpStatus.TooManyRequests,
  HttpStatus.NotFound,
]

export function getResponseErrorMessage(responseData: unknown) {
  if (
    responseData &&
    typeof responseData === 'object' &&
    'errorMessage' in responseData &&
    typeof responseData.errorMessage === 'string'
  ) {
    return responseData.errorMessage.replace(UUID_REGEX, '')
  }

  return ''
}

export function getMaskedPath(path?: string) {
  if (!path) return ''

  return path
    .replace(UUID_REGEX, '{uuid}')
    .replace(WEBFLOW_COLLECTION_ID_REGEX, '{collection_id}')
    .replace(LICENSES_VALIDATION, '/licenses/{license_number}/validation')
    .replace(VIN_VALIDATION, '/vins/{vin_number}/validation')
}
