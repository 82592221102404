import styled from 'styled-components'

import { AnimationSizeMap } from '@/utils/constants'

export const Container = styled.div<{ $full?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${({ $full }) => ($full ? '100vh' : 'auto')};
  background-color: transparent;

  p {
    margin-top: var(--space--medium);
    opacity: 0.6;
    font-weight: var(--font_weight--bold);
    text-align: center;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin: 0 auto;
`

export const Message = styled.p`
  text-align: center;
`

export const Animation = styled.img<{ $size?: string }>`
  max-height: ${({ $size = 'default' }) => AnimationSizeMap[$size]};
  max-width: ${({ $size = 'default' }) => AnimationSizeMap[$size]};
`
