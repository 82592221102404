const INVALID_PROPS_GROUPS = {
  border: ['borderBottom', 'borderColor', 'borderRadius', 'backgroundColor'],
  // We should prefix these with $
  componentState: [
    'narrow',
    'isActive',
    'isBorderLess',
    'isExpanded',
    'isLoading',
    'reducedLabel',
    'isNarrow',
    'isReducedLabel',
    'active',
    'expanded',
    'isSticky',
    'isWide',
    'select',
    'small',
    'wide',
  ],
  dimensions: [
    'height',
    'maxHeight',
    'maxWidth',
    'minHeight',
    'minWidth',
    'width',
  ],
  flexbox: [
    'alignContent',
    'alignItems',
    'alignSelf',
    'flexDirection',
    'flexGrow',
    'flexWrap',
    'justifyContent',
    'justifyItems',
    'justifySelf',
  ],
  formProperties: ['defaultOpen', 'initialTouched', 'initialValue', 'touched'],
  grid: [
    'gap',
    'gridAutoColumns',
    'gridAutoFlow',
    'gridAutoRows',
    'gridColumn',
    'gridColumnGap',
    'gridTemplateColumns',
    'gridTemplateRows',
  ],
  margin: ['margin', 'marginTop', 'marginLeft', 'marginRight', 'marginBottom'],
  padding: [
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingX',
    'paddingY',
  ],
  positionDisplay: ['sticky'],
} as const

export function shouldForwardProp(prop) {
  const allInvalidProps = Object.values(INVALID_PROPS_GROUPS).flat()
  return !allInvalidProps.includes(prop)
}
