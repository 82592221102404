import { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { type AuthSession, signOut } from 'aws-amplify/auth'
import { useTranslation } from 'react-i18next'

import AppError from '@/modules/AppError'

import Dialog from '@/components/Dialog'
import Spinner from '@/components/Spinner'

import { isEmpty } from '@/utils'
import { partnerKeys, useLocations } from '@/utils/api/partner/hooks'
import type { Employee } from '@/utils/api/partner/types'
import { EmployeeRoles, StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'
import { usePartnerData, useSetUser } from '@/utils/store/auth'
import type { AgentLocation } from '@/utils/store/preferences'
import { useSetPreferredLocation } from '@/utils/store/preferences'

import ConsentsDialog from './ConsentsDialog'
import NoPartnerDialog from './NoPartnerDialog'
import SelectStoreDialog from './SelectStoreDialog'

function getPartnerDataFromAuthSession(authSession: AuthSession) {
  const partner =
    authSession?.tokens?.accessToken?.payload?.['cognito:groups']?.[0]
  const name = partner?.split(':')?.[2]

  return { name }
}

function SelectLocation() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const setUser = useSetUser()
  const setPreferredLocation = useSetPreferredLocation()
  const { name } = usePartnerData()
  const [indexSelected, setIndexSelected] = useState('')
  const [selectedLocation, setSelectedLocation] = useState<{
    externalId: string
    id: string
  } | null>(null)

  const { data, error, isLoading } = useLocations({ name })

  const employeeSelfData = queryClient.getQueryData<Employee>(
    partnerKeys.employee(),
  )

  const isInsuranceAgent =
    employeeSelfData?.role === EmployeeRoles.InsuranceAgent

  async function handleSignOut() {
    await signOut()

    storage.clear()
    setUser(null)

    location.reload()
  }

  function changeHandler(event) {
    setIndexSelected(event.target.value)
  }

  function handleSetLocation(manualSelectedLocation?: AgentLocation) {
    const location = !isEmpty(manualSelectedLocation)
      ? manualSelectedLocation
      : selectedLocation

    storage.set(StorageKey.SelectedLocation, JSON.stringify(location))

    setPreferredLocation(location)
  }

  if (!name) return <NoPartnerDialog handleSignOut={handleSignOut} />

  if (!data) {
    return (
      <Dialog title={t('selectLocation.header')} narrow>
        {isLoading ? (
          <Spinner message={t('selectLocation.loading.message')} />
        ) : (
          <AppError error={error} />
        )}
      </Dialog>
    )
  }

  if (selectedLocation && !isInsuranceAgent)
    return (
      <ConsentsDialog
        handleSetLocation={handleSetLocation}
        handleSignOut={handleSignOut}
      />
    )

  return (
    <SelectStoreDialog
      handleSetLocation={() => {
        setSelectedLocation(data[indexSelected])
        if (isInsuranceAgent) handleSetLocation(data[indexSelected])
      }}
      changeHandler={changeHandler}
      data={data}
      handleSignOut={handleSignOut}
      indexSelected={indexSelected}
    />
  )
}

export { getPartnerDataFromAuthSession }
export default SelectLocation
