import { IconInfoCircle } from '@tabler/icons-react'

import type { TooltipProps } from '@/components/Tooltip'
import Tooltip from '@/components/Tooltip'

export function InfoTooltip({ children, ...props }: TooltipProps) {
  return (
    <Tooltip
      trigger={
        <IconInfoCircle color="var(--color--solid__primary)" size={16} />
      }
      {...props}
    >
      {children}
    </Tooltip>
  )
}
