import type { ComponentProps } from 'react'

import { Portal, Root } from '@radix-ui/react-popover'
import { useTranslation } from 'react-i18next'

import type { Side } from '@/utils/constants/enums'

import * as Styled from './styled'

type PopoverProps = {
  asChild?: boolean
  contentProps?: ComponentProps<typeof Styled.Content>
  disabled?: boolean
  // used for the copy button, to show the copied icon
  onMouseEnter?: () => void
  // used for the copy button, to show the copied icon
  onMouseLeave?: () => void
  portalProps?: ComponentProps<typeof Portal>
  showCloseButton?: boolean
  side?: Side
  trigger?: JSX.Element | React.ReactNode
  triggerAsChild?: boolean
  triggerProps?: ComponentProps<typeof Styled.Trigger>
} & ComponentProps<typeof Root>

function Popover({
  asChild,
  children,
  contentProps,
  disabled,
  portalProps,
  showCloseButton = true,
  side,
  trigger,
  triggerAsChild = asChild,
  triggerProps,
  ...props
}: PopoverProps) {
  const { t } = useTranslation()

  return (
    <Root {...props}>
      {trigger ? (
        <Styled.Trigger
          disabled={disabled}
          {...props}
          {...triggerProps}
          asChild={triggerAsChild}
        >
          {trigger}
        </Styled.Trigger>
      ) : null}
      <Portal {...props} {...portalProps}>
        <Styled.Content side={side} {...props} {...contentProps}>
          {children}
          {showCloseButton ? (
            <Styled.Close aria-label={t('close')}>
              <p>{t('close')}</p>
            </Styled.Close>
          ) : null}
          <Styled.ShadowArrow />
          <Styled.Arrow />
        </Styled.Content>
      </Portal>
    </Root>
  )
}

export default Popover
