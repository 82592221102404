import styled from 'styled-components'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--space--medium);
  padding: var(--space--small);
  container: app-error / inline-size;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space--xsmall);

    svg {
      color: var(--color--solid__danger);
    }
  }

  img {
    width: 100%;
    max-width: 100px;

    @container app-error (max-width: 500px) {
      display: none;
    }
  }
`

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space--small);
  max-width: var(--size--toast);
  margin-bottom: var(--space--small);

  form {
    display: flex;
    flex-direction: column;
    margin-top: var(--space--medium);
  }
`

export const SentryDescription = styled.p`
  opacity: 0.8;
`
