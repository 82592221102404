import { createContext, useContext, useMemo, useState } from 'react'

import { StorageKey } from '@/utils/constants/enums'
import * as storage from '@/utils/storage'

function getInitialColorScheme(): ColorScheme {
  const systemPreference = globalThis.matchMedia('(prefers-color-scheme: dark)')
    .matches
    ? ColorSchemeVariables.dark
    : ColorSchemeVariables.default

  const localColorScheme = storage.get(StorageKey.ColorScheme) as
    | ColorScheme
    | undefined

  return localColorScheme ?? systemPreference
}

export type ColorScheme = 'dark' | 'default'

export const ColorSchemeVariables = {
  dark: 'dark' as ColorScheme,
  default: 'default' as ColorScheme,
}

const defaultColorSchemeState = {
  colorScheme: ColorSchemeVariables.default,
  toggleColorScheme: () => undefined,
} as const

export type ColorSchemeState = typeof defaultColorSchemeState

// CONTEXT
export const ColorSchemeContext = createContext<ColorSchemeState>(
  defaultColorSchemeState,
)
export const useColorSchemeContext = () => useContext(ColorSchemeContext)

// HOOK

export function useColorScheme() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    getInitialColorScheme,
  )

  return useMemo(
    () =>
      ({
        colorScheme,
        toggleColorScheme() {
          setColorScheme((prevScheme) => {
            const nextColorScheme =
              prevScheme === ColorSchemeVariables.default
                ? ColorSchemeVariables.dark
                : ColorSchemeVariables.default
            storage.set(StorageKey.ColorScheme, nextColorScheme) // Store the user theme preference
            return nextColorScheme
          })
        },
      }) as ColorSchemeState,
    [colorScheme],
  )
}
