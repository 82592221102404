import * as i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
import LocizeBackend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import locizeLastused from 'locize-lastused'
import { initReactI18next } from 'react-i18next'

import { isProduction } from '@/utils/constants/env'
import { SystemLanguage } from '@/utils/schema/language'

const { VITE_LOCIZE_API_KEY } = import.meta.env

const locizeOptions = {
  projectId: '177c84e6-5d38-417f-bf25-3d2dacea0a09',
  referenceLng: SystemLanguage.EnUS,
} as const

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const PRODUCTION_LOCALIZATION_CACHE_TIME = 6 * 60 * 60 * 1000 // 6 hours

const cacheOptions = {
  expirationTime: isProduction ? PRODUCTION_LOCALIZATION_CACHE_TIME : 0,
} as const

let i18nInstance = i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .use(locizePlugin) // old locize editor (will be removed in future)
  .use(LanguageDetector)

if (VITE_LOCIZE_API_KEY) {
  ;(locizeOptions as any).apiKey = VITE_LOCIZE_API_KEY
  i18nInstance = i18nInstance.use(locizeLastused)
}

void i18nInstance.init({
  backend: {
    backendOptions: [cacheOptions, locizeOptions],
    backends: [LocalStorageBackend, LocizeBackend],
  },
  compatibilityJSON: 'v4',
  fallbackLng: SystemLanguage.EnUS,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  keySeparator: false,
  load: 'currentOnly', // This will only load the specified language, no unexpected fallbacks
  locizeLastUsed: VITE_LOCIZE_API_KEY ? locizeOptions : undefined,
  ns: 'backoffice',
  react: {
    bindI18n: 'languageChanged editorSaved',
  },
  saveMissing: true,
  supportedLngs: [SystemLanguage.EnUS, SystemLanguage.EsUS],
})

export default i18nInstance
