import styled from 'styled-components'

export const CloseButton = styled.button`
  padding: var(--space--medium);
  color: var(--color--solid-alt__neutral);
  width: max-content !important;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--corner_radius--large);
`

export const DisclaimerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 391px;
  padding: var(--space--medium);
  gap: var(--space--medium);
  background-color: var(--color--background-alt__neutral);
  border-radius: var(--corner_radius--large);
  font-size: var(--font_size--medium);

  h2 {
    font-size: var(--font_size--large);
    text-align: center;
  }

  button {
    width: 125px;
    align-self: end;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--space--medium);
    padding-left: var(--space--large);
  }
`

export const SelectLang = styled.select`
  background-color: var(--color--solid__primary);
  color: white;
  border: none;
  padding: var(--space--small) var(--space--medium);
  border-radius: var(--corner_radius--large);

  &:focus,
  &:active {
    outline: 0;
    border: none;
  }
`

export const ConsentList = styled.ol`
  list-style: inside;
`
