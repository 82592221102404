import { Button, Text } from '@ocho/aurora'
import { IconLogout } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Dialog from '@/components/Dialog'

type Props = {
  handleSignOut: () => void
}
function NoPartnerDialog({ handleSignOut }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog
      footerActions={
        <Button onClick={handleSignOut} type="button">
          <IconLogout />
          {t('signOut')}
        </Button>
      }
      title={t('selectLocation.noPartner')}
    >
      <Text>{t('contactAdmin')}</Text>
    </Dialog>
  )
}

export default NoPartnerDialog
