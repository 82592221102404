import { Nav } from '@/modules/SideNav/styled'

import { RootContainer, RootContent } from './styled'

export default function AppSkeleton() {
  return (
    <RootContainer>
      <Nav />
      <RootContent />
    </RootContainer>
  )
}
