import { NavLink as RouterNavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from '@/components/system'

export const NavHeadingContent = styled(Flex)<{ $expanded?: boolean }>`
  gap: var(--space--small);
  margin: ${({ $expanded }) =>
    $expanded ? '0 var(--space--small)' : undefined};
  text-decoration: none;
  color: inherit;
  align-items: flex-end;

  p {
    font-size: var(--font_size--small);
    font-weight: var(--font_weight--black);
    margin-right: var(--space--small);
  }

  @container nav (max-width: 200px) {
    .gliph {
      display: initial !important;
    }

    .logo {
      display: none !important;
    }
  }
`

export const NavLink = styled(RouterNavLink)`
  padding: var(--space--xsmall);
`

export const NavLogo = styled.svg<{
  $isExpanded?: boolean
}>`
  height: 25px;
  color: var(--color--ocho);
  margin: var(--space--large) auto 0;
  display: ${({ $isExpanded }) => ($isExpanded ? undefined : 'none')};

  &.gliph {
    display: ${({ $isExpanded }) => ($isExpanded ? 'none' : undefined)};
  }
`

export const Nav = styled.nav<{ $expanded?: boolean }>`
  height: 100%;
  display: flex;
  overflow: hidden;
  grid-area: navbar;
  resize: ${({ $expanded }) => ($expanded ? 'horizontal' : undefined)};
  align-items: center;
  flex-direction: column;
  gap: var(--space--large);
  justify-content: space-between;
  background-color: var(--color--background__solid);
  border-right: 1px solid var(--color--background__neutral);
  padding: 0 var(--space--small) var(--space--small);
  min-width: ${({ $expanded }) =>
    $expanded ? '150px' : 'calc(20px + calc(var(--space--small) * 4))'};
  min-width: ${({ $expanded }) => ($expanded ? '150px' : undefined)};
  max-width: ${({ $expanded }) => ($expanded ? undefined : '55px')};
  container: nav / inline-size;
`

export const NavList = styled.ul<{ $expanded?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  grid-auto-flow: row;
  list-style: none;
  gap: var(--space--xsmall);
  width: 100%;
  padding-bottom: var(--space--medium);

  a,
  button {
    display: inline-flex;
    width: 100%;
    gap: var(--space--small);
    font-weight: var(--font_weight--medium);
    align-items: center;
    text-decoration: none;
    justify-content: flex-start;
    background-color: transparent;
    font-size: var(--font_size--small);
    border-radius: var(--corner_radius--medium);
    padding: var(--space--small);
    color: var(--color--text-alt__neutral);

    &.active {
      position: relative;
    }

    &:hover {
      background-color: var(--color--background__neutral);
    }

    &.active,
    &[data-state='open'] {
      opacity: 1;
      font-weight: var(--font_weight--bold);
      background-color: var(--color--background__neutral);
      color: var(--color--text__neutral);
    }

    svg {
      margin-left: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }

    span {
      ${({ $expanded }) => ($expanded ? undefined : 'display: none;')}
    }
  }
`
