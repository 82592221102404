import type { GetLoginEventsData } from '@/utils/api/api'

export enum AuthEventTypes {
  ChangePassword = 'ChangePassword',
  ForgotPassword = 'ForgotPassword',
  SignIn = 'SignIn',
  SignOut = 'SignOut',
  SignUp = 'SignUp',
}

export enum AuthEventResponses {
  Fail = 'Fail',
  InProgress = 'InProgress',
  Pass = 'Pass',
}

export type AuthEvent = {
  challengeResponses: {
    challengeName: 'PASSWORD' | 'UNKNOWN_TO_SDK_VERSION'
    challengeResponse: 'CodeMismatch' | 'ExpiredCode' | 'Failure' | 'Success'
  }[]
  creationDate: Date
  eventContextData: {
    city: string
    country: string
    deviceName: string
    ipAddress: string
  }
  eventId: string
  eventResponse: 'Fail' | 'InProgress' | 'Pass'
  eventRisk: {
    compromisedCredentialsDetected: boolean
    riskDecision: 'ACCOUNT_TAKEOVER' | 'NO_RISK'
  }
  eventTypeAsString: AuthEventTypes
}

export type LoginEventsResponse = {
  data: {
    authEvents: AuthEvent[]
  }
} & GetLoginEventsData
