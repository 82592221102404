import { getCurrentScope } from '@sentry/react'
import axios from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import { v4 as uuidv4 } from 'uuid'

import { getIdemPotencyKey } from '@/utils'
import { HttpStatus } from '@/utils/constants/enums'

import {
  responseErrorInterceptor,
  responseFulfilledInterceptor,
} from './interceptors'
import { getIdToken, IdempotencyKeyHeaderName } from './utils'

const { VITE_API_HOST } = import.meta.env

const axiosInstance = axios.create({
  baseURL: `${VITE_API_HOST}/api/v1`,
  withCredentials: true,
})

const MAX_RETRIES_COUNT = 5

axiosRetry(axiosInstance, {
  onRetry(_retryCount, _error, requestConfig) {
    if (requestConfig.headers?.[IdempotencyKeyHeaderName]) {
      requestConfig.headers[IdempotencyKeyHeaderName] = getIdemPotencyKey()
    }
  },
  retries: MAX_RETRIES_COUNT,
  retryCondition(error) {
    const isTooManyRequests =
      error.response?.status === HttpStatus.TooManyRequests

    const willRetry =
      isTooManyRequests || isNetworkOrIdempotentRequestError(error)

    return willRetry
  },
  retryDelay: axiosRetry.exponentialDelay,
})

axiosInstance.interceptors.response.use(
  responseFulfilledInterceptor,
  responseErrorInterceptor,
)

axiosInstance.interceptors.request.use(async (request) => {
  const transactionId = uuidv4()

  getCurrentScope().setTag('transaction_id', transactionId)
  const idToken = await getIdToken()

  if (idToken) request.headers['Authorization'] = `Bearer ${idToken}`
  return request
})

export const { HOST } = import.meta.env

export * as application from './application'
export * as attachment from './attachment'
export * as authentication from './authentication'
export * as communication from './communication'
export * as customer from './customer'
export * as encryption from './encryption'
export * as insurances from './insurances'
export * as issuer from './issuer'
export * as issuerQuestions from './issuer-questions'
export * as misc from './misc'
export * as note from './note'
export * as partner from './partner'
export * as payment from './payment'
export * as permissions from './permissions'
export * as policy from './policy'
export * as product from './product'
export * as tasks from './tasks'
export * as user from './user'

export { axiosInstance }
