import {
  Close as DialogPrimitiveClose,
  Content as DialogPrimitiveContent,
  Overlay as DialogPrimitiveOverlay,
  Trigger as DialogPrimitiveTrigger,
  Root,
} from '@radix-ui/react-dialog'
import styled, { css, keyframes } from 'styled-components'

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const slideFromRight = keyframes`
  from {
    opacity: 0.5;
    transform: translate(100%, -50%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`

const overlayShow = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const RootContainer = styled(Root)`
  position: relative;
`

export const Close = styled(DialogPrimitiveClose)`
  background-color: transparent;
  border-radius: 50%;
  width: var(--size--button);
  height: var(--size--button);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space--xsmall);
  margin-right: var(--space--small);
  color: currentcolor;

  &:hover {
    background-color: var(--color--background__neutral);
  }
`

export const Trigger = styled(DialogPrimitiveTrigger)<{
  $isOutlined: boolean
  asChild?: boolean
}>`
  ${({ $isOutlined, asChild }) =>
    !asChild &&
    css`
      background-color: ${$isOutlined
        ? 'transparent'
        : 'var(--color--solid__primary)'};
      color: ${$isOutlined
        ? 'var(--color--solid__primary)'
        : 'var(--color--blanco)'};
      border-radius: var(--corner_radius--medium);
      width: fit-content;
      height: fit-content;
    `}
`

export const StyledOverlay = styled(DialogPrimitiveOverlay)<{
  $isDarkMode?: boolean
}>`
  background-color: ${({ $isDarkMode }) =>
    $isDarkMode
      ? 'var(--colors--dialog_shadow__dark)'
      : 'var(--colors--dialog_shadow__2)'};
  position: fixed;
  inset: 0;
  z-index: var(--z_index--overlay);

  @media (prefers-reduced-motion: no-preference) {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
`

export const ContentContainer = styled(DialogPrimitiveContent)<{
  $drawer: boolean
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ $drawer }) => ($drawer ? 0 : 'var(--space--medium)')};
  max-height: 100%;
  width: 100%;
  transition: transform 0.3s;
  z-index: var(--z_index--overlay);

  @media (prefers-reduced-motion: no-preference) {
    animation: ${({ $drawer }) => ($drawer ? slideFromRight : contentShow)}
      150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:focus {
    outline: none;
  }
`

export const Content = styled.div<{
  $drawer: boolean
  $drawerSize: 'default' | 'large' | 'xlarge'
  narrow: boolean
}>`
  margin: 0 auto;
  padding: var(--space--medium);
  margin-right: ${({ $drawer }) => ($drawer ? '0' : 'auto')};
  display: grid;
  border: 1px solid var(--color--background__neutral);
  background-color: var(--color--background-alt__neutral);
  grid-template-rows: auto 1fr auto;
  height: ${({ $drawer }) => ($drawer ? '100vh' : 'auto')};
  max-height: ${({ $drawer }) =>
    $drawer ? 'auto' : 'calc(100vh - calc(2 * var(--space--medium)))'};
  border-radius: ${({ $drawer }) =>
    $drawer ? '0' : 'var(--corner_radius--large)'};
  box-shadow: var(--dialog--box--shadow);
  max-width: ${({ $drawer, $drawerSize, narrow }) =>
    $drawer
      ? $drawerSize === 'default'
        ? 'var(--size--drawer-default)'
        : $drawerSize === 'xlarge'
          ? 'var(--size--drawer-xlarge)'
          : 'var(--size--drawer-large)'
      : narrow
        ? 'var(--size--dialog-narrow)'
        : 'var(--size--dialog)'};
`

export const DescriptionContainer = styled.div<{
  $allowOverflow?: boolean
}>`
  overflow: ${({ $allowOverflow }) => $allowOverflow && 'auto'};
  display: grid;
`
